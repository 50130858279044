// extracted by mini-css-extract-plugin
export var addQuantity = "bagCard-module--addQuantity--b3e4c";
export var bagCardContainer = "bagCard-module--bagCardContainer--28e92";
export var container = "bagCard-module--container--46b6a";
export var imageContainer = "bagCard-module--imageContainer--7f6ef";
export var productInfoContainer = "bagCard-module--productInfoContainer--36917";
export var productNameClass = "bagCard-module--productNameClass--f0cd9";
export var quantityCounter = "bagCard-module--quantityCounter--bad74";
export var quantityNumber = "bagCard-module--quantityNumber--2dc17";
export var subtractQuantity = "bagCard-module--subtractQuantity--5cf7a";
export var textAndQuantityContainer = "bagCard-module--textAndQuantityContainer--25470";
export var theImage = "bagCard-module--theImage--782a9";
export var xButton = "bagCard-module--xButton--cc6cd";