exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/About/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-collections-index-js": () => import("./../../../src/pages/Collections/index.js" /* webpackChunkName: "component---src-pages-collections-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/ContactUs/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lookbook-index-js": () => import("./../../../src/pages/Lookbook/index.js" /* webpackChunkName: "component---src-pages-lookbook-index-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/Shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/Terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-template-product-js": () => import("./../../../src/template/product.js" /* webpackChunkName: "component---src-template-product-js" */)
}

