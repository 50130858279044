// extracted by mini-css-extract-plugin
export var bagContainer = "Bag-module--bagContainer--ef801";
export var bagContents = "Bag-module--bagContents--c38ff";
export var bagFooter = "Bag-module--bagFooter--1fa76";
export var bagHeader = "Bag-module--bagHeader--7def4";
export var bagOpacity = "Bag-module--bagOpacity--337e2";
export var bagTitle = "Bag-module--bagTitle--9bb84";
export var checkOutButton = "Bag-module--checkOutButton--153c5";
export var footerDivider = "Bag-module--footerDivider--60814";
export var headerDivider = "Bag-module--headerDivider--9d0db";
export var subtotalDisplay = "Bag-module--subtotalDisplay--10513";
export var subtotalDivider = "Bag-module--subtotalDivider--1dee2";
export var theBag = "Bag-module--theBag--a2a7f";
export var xButton = "Bag-module--xButton--4a57a";
export var xButtonContainer = "Bag-module--xButtonContainer--44b3c";